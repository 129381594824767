<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2"  style="font-size: 36px;">今日异常品质</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board :config="config" style="width:11.575rem;height:4.28rem" />
      </div>
    </div>
  </div>
</template>

<script>
import { findJobList } from "../../libs/axios";
export default {
  data() {
    return {
      config: {
        header: ["工单号", "异常工序", "责任人", "状态"],
        data: [
        ],
        rowNum: 6, //表格行数
        headerHeight: 45,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [150,180,550,220,280],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {
    this.init();
    setInterval(this.init, 120000);
  },
  methods: {
    init() {
      this.getJobList();
    },
    getJobList() {
      this.loading = true;
      findJobList().then((res) => {
        this.loading = false;
        this.$set(this.config, this.config.data, res.data);
        this.config.data = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 0.2rem;
  height: 7.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 6.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
    font-size:0.5rem;
  }
  .text {
    font-size: 0.6rem;
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
    font-size: 0.3rem;
  }
  .header-item{
    font-size: 0.4rem;
  }
    .row-item{
    font-size: 0.5rem;
    color:"#ffc103"
  }
  .ceil{
    color: rgb(15, 211, 58);
  }
}
</style>