<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import {selectJobPie} from '../../../../../libs/axios';
export default {
  data () {
    return {
      cdata: "",
    }
  },
  components: {
    Chart,
  },
  mounted () {
     this.setData();
     setInterval(this.setData,120000);
  },
  beforeDestroy () {
    // clearInterval(this.drawTiming);
  },
  methods: {
    setData () {
      this.loading = true;
      selectJobPie().then((res) => {
        this.loading = false;
          this.cdata=res.data;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
</style>