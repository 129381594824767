<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color: #5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2" style="font-size: 36px"
            >返工预警人员</span
          >
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board
          :config="config"
          style="width: 4.375rem; height: 4.2rem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { selectJobNoisSolve } from "../../libs/axios";
export default {
  data() {
    return {
      config: {
        rowNum: 1, //表格行数
        data: [],
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: false,
        waitTime: 7000,
        columnWidth: [300],
        align: ["center"],
      },
    };
  },
  components: {},
  mounted() {
    this.init();
    setInterval(this.init, 120000);
  },
  methods: {
    init() {
      this.getJobShowList();
    },
    getJobShowList() {
      this.loading = true;
      selectJobNoisSolve({
        jobType: "0",
      }).then((res) => {
        this.loading = false;
        this.$set(this.config, this.config.data, res.data);
        this.config.data = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
#centerLeft1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
  .hh-t {
    height: 100%;
    width: 100%;
    line-height: normal;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .img {
    border-radius: 50%;
    border: 1px solid red;
    height: 150px;
    width: 150px;
  }
  .imf {
       font-size: 35px;
    color: yellow;
    width: 260px;
    word-break: break-all;
    white-space: normal;
    text-align: left;
  }
}
</style>