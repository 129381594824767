<template>
  <div id="center">
    <div class="up">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.text" style='display:block;word-break: break-all;word-wrap: break-word;'>
        <div style='width: 1.25rem;display:block;word-break: break-all;word-wrap: break-word;width: 3.25rem;
    line-height: 0.75rem;    margin-left: 0.3rem; '> 
    <div style="   height: 1.5rem;color: #0fd33a;">
{{item.title}}</div>
 <div style="    text-align: center;
    font-size: xxx-large;
    font-weight: 900;
    color: red;">
{{item.number.number}}</div>
</div>
        <!-- <p class="ml-3 colorBlue fw-b" style="line-height: 50px;">{{item.text}}</p> -->
        <!-- <div>
          <dv-digital-flop :config="item.data" style="width:1.25rem;height:.625rem;font-size: 56px;" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { selectJobTotailInfor } from "../../libs/axios";
export default {
  data () {
    return {
      titleItem: [
        {
          title: "总品质工单数量",
          number: {
            number: [120],
          }
        },
        {
          title: "已经处理工单数量",
          number: {
            number: [18],
          }
        },
        {
          title: "处理完成率",
          number: {
            number: [2],
          }
        },
        {
          title: "总抽检次数",
          number: {
            number: [14],
          }
        },
        {
          title: "合格数（大于90%）",
          number: {
            number: [106],
          }
        },
        {
          title: "不合格数（小于90%）",
          number: {
            number: [100],
          }
        }
      ],
     
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 60,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5"
              }
            }
          }
        },
        {
          id: "centerRate2",
          tips: 40,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad"
              }
            }
          }
        }
      ]
    };
  },
  components: {
    // centerChart1,
    // centerChart2
  },
    mounted() {
    this.init();
     setInterval(this.init,120000);
  },
  methods: {
    init() {
      this.getJobTotailInfor();
    },
     getJobTotailInfor() {
       
      this.loading = true;
      selectJobTotailInfor().then((res) => {
          this.titleItem=res.data;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 32%;
      height: 2.475rem;
    }
  }
  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 3.1875rem;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
    }
    .ranking {
      padding: 1.125rem;
      width: 100%;
    }
    .percent {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 100%;
        height:3rem;
        span {
          margin-top: 0.3875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
 .active-ring-name {
    // 调整圆环字体大小
    font-size: 25px !important;
  }
</style>